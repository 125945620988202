"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.WalletTypes = exports.Mainnet = exports.Baklava = exports.Alfajores = exports.NetworkNames = exports.images = exports.SupportedProviders = exports.localStorageKeys = void 0;
exports.localStorageKeys = {
    lastUsedAddress: 'use-contractkit/last-used-address',
    lastUsedNetwork: 'use-contractkit/last-used-network',
    lastUsedWalletType: 'use-contractkit/last-used-wallet',
    lastUsedWalletArguments: 'use-contractkit/last-used-wallet-arguments',
};
var SupportedProviders;
(function (SupportedProviders) {
    SupportedProviders["WalletConnect"] = "Wallet Connect";
    SupportedProviders["MetaMask"] = "MetaMask";
    SupportedProviders["CeloExtensionWallet"] = "Celo Extension Wallet";
    SupportedProviders["Ledger"] = "Ledger";
    SupportedProviders["Valora"] = "Valora";
    SupportedProviders["PrivateKey"] = "Private key";
})(SupportedProviders = exports.SupportedProviders || (exports.SupportedProviders = {}));
// can't figure out how to bundle images yet
// so this is our workaround
exports.images = (_a = {},
    _a[SupportedProviders.Ledger] = 'https://www.ledger.com/wp-content/uploads/2020/02/puce_blue.png',
    _a[SupportedProviders.WalletConnect] = 'https://gblobscdn.gitbook.com/spaces%2F-LJJeCjcLrr53DcT1Ml7%2Favatar.png?alt=media',
    _a[SupportedProviders.MetaMask] = 'https://metamask.io/images/favicon-256.png',
    _a[SupportedProviders.Valora] = 'https://valoraapp.com/favicon.ico',
    _a[SupportedProviders.CeloExtensionWallet] = 'https://metamask.io/images/favicon-256.png',
    _a);
var NetworkNames;
(function (NetworkNames) {
    NetworkNames["Alfajores"] = "Alfajores";
    NetworkNames["Baklava"] = "Baklava";
    NetworkNames["Mainnet"] = "Mainnet";
})(NetworkNames = exports.NetworkNames || (exports.NetworkNames = {}));
exports.Alfajores = {
    name: NetworkNames.Alfajores,
    rpcUrl: 'https://alfajores-forno.celo-testnet.org',
    graphQl: 'https://alfajores-blockscout.celo-testnet.org/graphiql',
    explorer: 'https://alfajores-blockscout.celo-testnet.org',
    chainId: 44787,
};
exports.Baklava = {
    name: NetworkNames.Baklava,
    rpcUrl: 'https://baklava-forno.celo-testnet.org',
    graphQl: 'https://baklava-blockscout.celo-testnet.org/graphiql',
    explorer: 'https://baklava-blockscout.celo-testnet.org',
    chainId: 62320,
};
exports.Mainnet = {
    name: NetworkNames.Mainnet,
    rpcUrl: 'https://forno.celo.org',
    graphQl: 'https://explorer.celo.org/graphiql',
    explorer: 'https://explorer.celo.org',
    chainId: 42220,
};
var WalletTypes;
(function (WalletTypes) {
    WalletTypes["Unauthenticated"] = "Unauthenticated";
    WalletTypes["PrivateKey"] = "PrivateKey";
    WalletTypes["WalletConnect"] = "WalletConnect";
    WalletTypes["Ledger"] = "Ledger";
    WalletTypes["CeloExtensionWallet"] = "CeloExtensionWallet";
    WalletTypes["Metamask"] = "Metamask";
    WalletTypes["DappKit"] = "DappKit";
})(WalletTypes = exports.WalletTypes || (exports.WalletTypes = {}));
