"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
var _a, _b;
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContractKitProvider = exports.useContractKit = void 0;
var react_1 = __importStar(require("react"));
var unstated_next_1 = require("unstated-next");
var connectors_1 = require("./connectors");
var constants_1 = require("./constants");
var modals_1 = require("./modals");
var lastUsedNetworkName = constants_1.Mainnet.name;
var lastUsedAddress = '';
var lastUsedWalletType = constants_1.WalletTypes.Unauthenticated;
var lastUsedWalletArguments = [];
function localStorageOperations() {
    if (typeof localStorage === 'undefined') {
        return;
    }
    var localLastUsedNetworkName = localStorage.getItem(constants_1.localStorageKeys.lastUsedNetwork);
    if (localLastUsedNetworkName) {
        lastUsedNetworkName = localLastUsedNetworkName;
    }
    var localLastUsedAddress = localStorage.getItem(constants_1.localStorageKeys.lastUsedAddress);
    if (localLastUsedAddress) {
        lastUsedAddress = localLastUsedAddress;
    }
    var localLastUsedWalletType = localStorage.getItem(constants_1.localStorageKeys.lastUsedWalletType);
    if (localLastUsedWalletType) {
        lastUsedWalletType = localLastUsedWalletType;
    }
    var localLastUsedWalletArguments = localStorage.getItem(constants_1.localStorageKeys.lastUsedWalletArguments);
    if (localLastUsedWalletArguments) {
        try {
            lastUsedWalletArguments = JSON.parse(localLastUsedWalletArguments);
        }
        catch (e) {
            lastUsedWalletArguments = [];
        }
    }
}
localStorageOperations();
var defaultNetworks = [constants_1.Mainnet, constants_1.Alfajores];
var lastUsedNetwork = defaultNetworks.find(function (n) { return n.name === lastUsedNetworkName; }) || constants_1.Alfajores;
var connectorTypes = (_a = {},
    _a[constants_1.WalletTypes.Unauthenticated] = connectors_1.UnauthenticatedConnector,
    _a[constants_1.WalletTypes.PrivateKey] = connectors_1.PrivateKeyConnector,
    _a[constants_1.WalletTypes.Ledger] = connectors_1.LedgerConnector,
    _a[constants_1.WalletTypes.WalletConnect] = connectors_1.WalletConnectConnector,
    _a[constants_1.WalletTypes.CeloExtensionWallet] = connectors_1.CeloExtensionWalletConnector,
    _a[constants_1.WalletTypes.Metamask] = null,
    _a[constants_1.WalletTypes.DappKit] = connectors_1.DappKitConnector,
    _a);
var initialConnector;
if (lastUsedWalletType) {
    try {
        initialConnector = new ((_b = connectorTypes[lastUsedWalletType]).bind.apply(_b, __spreadArray([void 0, lastUsedNetwork], lastUsedWalletArguments)))();
    }
    catch (e) {
        initialConnector = new connectors_1.UnauthenticatedConnector(lastUsedNetwork);
    }
}
function Kit(_a) {
    var _this = this;
    var _b = _a === void 0 ? {
        networks: defaultNetworks,
        dappName: '',
        dappDescription: '',
        dappIcon: '',
        dappUrl: '',
    } : _a, _c = _b.networks, networks = _c === void 0 ? defaultNetworks : _c, dappName = _b.dappName, dappDescription = _b.dappDescription, dappIcon = _b.dappIcon, dappUrl = _b.dappUrl;
    var dapp = react_1.useState({
        name: dappName,
        description: dappDescription,
        icon: dappIcon || dappUrl + "/favicon.ico",
        url: dappUrl,
    })[0];
    var _d = react_1.useState(lastUsedAddress), address = _d[0], setAddress = _d[1];
    var _e = react_1.useState(null), connectionCallback = _e[0], setConnectionCallback = _e[1];
    var initialNetwork = networks.find(function (n) { return n.name === lastUsedNetworkName; }) || defaultNetworks[0];
    if (!initialNetwork) {
        throw new Error('Unknown network');
    }
    var _f = react_1.useState(initialConnector), connection = _f[0], setConnection = _f[1];
    var _g = react_1.useState(initialNetwork), network = _g[0], updateNetwork = _g[1];
    var _h = react_1.useState(0), pendingActionCount = _h[0], setPendingActionCount = _h[1];
    react_1.useEffect(function () {
        var account = connection === null || connection === void 0 ? void 0 : connection.kit.defaultAccount;
        if (account) {
            setAddress(account);
            localStorage.setItem(constants_1.localStorageKeys.lastUsedAddress, account);
        }
    }, [connection === null || connection === void 0 ? void 0 : connection.kit]);
    react_1.useEffect(function () {
        if (localStorage.getItem(constants_1.localStorageKeys.lastUsedNetwork) === network.name) {
            return;
        }
        localStorage.setItem(constants_1.localStorageKeys.lastUsedNetwork, network.name);
        setConnection(function (c) {
            var Constructor = connectorTypes[c.type];
            if (!Constructor) {
                return null;
            }
            var lastUsedWalletArguments = JSON.parse(localStorage.getItem(constants_1.localStorageKeys.lastUsedWalletArguments) || '[]');
            return new (Constructor.bind.apply(Constructor, __spreadArray([void 0, network], lastUsedWalletArguments)))();
        });
    }, [network]);
    var destroy = react_1.useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, connection.close()];
                case 1:
                    _a.sent();
                    localStorage.removeItem(constants_1.localStorageKeys.lastUsedAddress);
                    localStorage.removeItem(constants_1.localStorageKeys.lastUsedWalletType);
                    localStorage.removeItem(constants_1.localStorageKeys.lastUsedWalletArguments);
                    setAddress('');
                    setConnection(new connectors_1.UnauthenticatedConnector(network));
                    return [2 /*return*/];
            }
        });
    }); }, [network, connection]);
    var connect = react_1.useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        var connectionResultPromise, connector;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    connectionResultPromise = new Promise(function (resolve) {
                        var connectionResultCallback = function (x) { return resolve(x); };
                        // has to be like this and not like setConnectionCallback(connectionResultCallback)
                        // as React will try to run any function passed to set state
                        setConnectionCallback(function () { return connectionResultCallback; });
                    });
                    return [4 /*yield*/, connectionResultPromise];
                case 1:
                    connector = _a.sent();
                    if (connector === false) {
                        // dismissed
                        setConnectionCallback(null);
                        throw new Error('Connection cancelled');
                    }
                    if (connector.onNetworkChange) {
                        connector.onNetworkChange(function (chainId) {
                            var network = networks === null || networks === void 0 ? void 0 : networks.find(function (n) { return n.chainId === chainId; });
                            network && updateNetwork(network);
                        });
                    }
                    setConnection(connector);
                    setConnectionCallback(null);
                    return [2 /*return*/, connector];
            }
        });
    }); }, [network]);
    var getConnectedKit = react_1.useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        var initialisedConnection;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    initialisedConnection = connection;
                    if (!(connection.type === constants_1.WalletTypes.Unauthenticated)) return [3 /*break*/, 2];
                    return [4 /*yield*/, connect()];
                case 1:
                    initialisedConnection = _a.sent();
                    return [3 /*break*/, 4];
                case 2:
                    if (!!initialisedConnection.initialised) return [3 /*break*/, 4];
                    return [4 /*yield*/, initialisedConnection.initialise()];
                case 3:
                    _a.sent();
                    _a.label = 4;
                case 4: return [2 /*return*/, initialisedConnection.kit];
            }
        });
    }); }, [connect, connection]);
    /**
     * Helper function for handling any interaction with a Celo wallet. Perform action will
     *    - open the action modal
     *    - handle multiple transactions in order
     */
    var performActions = react_1.useCallback(function () {
        var operations = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            operations[_i] = arguments[_i];
        }
        return __awaiter(_this, void 0, void 0, function () {
            var kit, results, _a, operations_1, op, _b, _c, e_1;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0: return [4 /*yield*/, getConnectedKit()];
                    case 1:
                        kit = _d.sent();
                        setPendingActionCount(operations.length);
                        results = [];
                        _a = 0, operations_1 = operations;
                        _d.label = 2;
                    case 2:
                        if (!(_a < operations_1.length)) return [3 /*break*/, 8];
                        op = operations_1[_a];
                        _d.label = 3;
                    case 3:
                        _d.trys.push([3, 5, , 6]);
                        _c = (_b = results).push;
                        return [4 /*yield*/, op(kit)];
                    case 4:
                        _c.apply(_b, [_d.sent()]);
                        return [3 /*break*/, 6];
                    case 5:
                        e_1 = _d.sent();
                        setPendingActionCount(0);
                        throw e_1;
                    case 6:
                        setPendingActionCount(function (c) { return c - 1; });
                        _d.label = 7;
                    case 7:
                        _a++;
                        return [3 /*break*/, 2];
                    case 8: return [2 /*return*/, results];
                }
            });
        });
    }, [getConnectedKit]);
    return {
        network: network,
        updateNetwork: updateNetwork,
        address: address,
        dappName: dapp.name,
        dapp: dapp,
        kit: connection.kit,
        walletType: connection.type,
        performActions: performActions,
        connect: connect,
        destroy: destroy,
        getConnectedKit: getConnectedKit,
        // private
        pendingActionCount: pendingActionCount,
        connectionCallback: connectionCallback,
    };
}
var KitState = unstated_next_1.createContainer(Kit);
exports.useContractKit = KitState.useContainer;
function ContractKitProvider(_a) {
    var children = _a.children, connectModal = _a.connectModal, actionModal = _a.actionModal, dappName = _a.dappName, dappDescription = _a.dappDescription, dappUrl = _a.dappUrl, dappIcon = _a.dappIcon, networks = _a.networks;
    return (react_1.default.createElement(KitState.Provider, { initialState: { networks: networks, dappName: dappName, dappDescription: dappDescription, dappUrl: dappUrl, dappIcon: dappIcon } },
        react_1.default.createElement(modals_1.ConnectModal, __assign({}, connectModal)),
        react_1.default.createElement(modals_1.ActionModal, __assign({}, actionModal)),
        children));
}
exports.ContractKitProvider = ContractKitProvider;
