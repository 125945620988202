"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
var constants_1 = require("../constants");
var cew_1 = require("./cew");
var ledger_1 = require("./ledger");
var private_key_1 = require("./private-key");
var valora_1 = require("./valora");
var wallet_connect_1 = require("./wallet-connect");
var defaultScreens = (_a = {},
    _a[constants_1.SupportedProviders.Ledger] = ledger_1.Ledger,
    _a[constants_1.SupportedProviders.Valora] = valora_1.Valora,
    _a[constants_1.SupportedProviders.WalletConnect] = wallet_connect_1.WalletConnect,
    // [SupportedProviders.MetaMask]: Metamask,
    _a[constants_1.SupportedProviders.CeloExtensionWallet] = cew_1.Metamask,
    _a[constants_1.SupportedProviders.PrivateKey] = private_key_1.PrivateKey,
    _a);
exports.default = defaultScreens;
