"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.WalletConnectConnector = exports.DappKitConnector = exports.CeloExtensionWalletConnector = exports.LedgerConnector = exports.PrivateKeyConnector = exports.UnauthenticatedConnector = void 0;
var constants_1 = require("./constants");
var contractkit_1 = require("@celo/contractkit");
var wallet_local_1 = require("@celo/wallet-local");
// we can't lazy load this due to the new tab bug, it must be imported
// so that the new tab handler fires.
var dappkit_wallet_1 = require("./dappkit-wallet");
/**
 * Connectors are our link between a DApp and the users wallet. Each
 * wallet has different semantics and these classes attempt to unify
 * them and present a workable API.
 */
var UnauthenticatedConnector = /** @class */ (function () {
    function UnauthenticatedConnector(n) {
        this.initialised = true;
        this.type = constants_1.WalletTypes.Unauthenticated;
        this.kit = contractkit_1.newKit(n.rpcUrl);
    }
    UnauthenticatedConnector.prototype.initialise = function () {
        return this;
    };
    UnauthenticatedConnector.prototype.close = function () {
        return;
    };
    return UnauthenticatedConnector;
}());
exports.UnauthenticatedConnector = UnauthenticatedConnector;
var PrivateKeyConnector = /** @class */ (function () {
    function PrivateKeyConnector(n, privateKey) {
        this.initialised = true;
        this.type = constants_1.WalletTypes.PrivateKey;
        localStorage.setItem(constants_1.localStorageKeys.lastUsedWalletType, constants_1.WalletTypes.PrivateKey);
        localStorage.setItem(constants_1.localStorageKeys.lastUsedWalletArguments, JSON.stringify([privateKey]));
        var wallet = new wallet_local_1.LocalWallet();
        wallet.addAccount(privateKey);
        this.kit = contractkit_1.newKit(n.rpcUrl, wallet);
        this.kit.defaultAccount = wallet.getAccounts()[0];
    }
    PrivateKeyConnector.prototype.initialise = function () {
        return this;
    };
    PrivateKeyConnector.prototype.close = function () {
        return;
    };
    return PrivateKeyConnector;
}());
exports.PrivateKeyConnector = PrivateKeyConnector;
var LedgerConnector = /** @class */ (function () {
    function LedgerConnector(network, index) {
        this.network = network;
        this.index = index;
        this.initialised = false;
        this.type = constants_1.WalletTypes.Ledger;
        localStorage.setItem(constants_1.localStorageKeys.lastUsedWalletType, constants_1.WalletTypes.Ledger);
        localStorage.setItem(constants_1.localStorageKeys.lastUsedWalletArguments, JSON.stringify([index]));
        this.kit = contractkit_1.newKit(network.rpcUrl);
    }
    LedgerConnector.prototype.initialise = function () {
        return __awaiter(this, void 0, void 0, function () {
            var TransportUSB, newLedgerWalletWithSetup, transport, wallet;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require('@ledgerhq/hw-transport-webusb')); })];
                    case 1:
                        TransportUSB = (_a.sent()).default;
                        return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require('@celo/wallet-ledger')); })];
                    case 2:
                        newLedgerWalletWithSetup = (_a.sent()).newLedgerWalletWithSetup;
                        return [4 /*yield*/, TransportUSB.create()];
                    case 3:
                        transport = _a.sent();
                        return [4 /*yield*/, newLedgerWalletWithSetup(transport, [this.index])];
                    case 4:
                        wallet = _a.sent();
                        this.kit = contractkit_1.newKit(this.network.rpcUrl, wallet);
                        this.kit.defaultAccount = wallet.getAccounts()[0];
                        this.initialised = true;
                        return [2 /*return*/, this];
                }
            });
        });
    };
    LedgerConnector.prototype.close = function () {
        return;
    };
    return LedgerConnector;
}());
exports.LedgerConnector = LedgerConnector;
var CeloExtensionWalletConnector = /** @class */ (function () {
    function CeloExtensionWalletConnector(network) {
        this.initialised = false;
        this.type = constants_1.WalletTypes.CeloExtensionWallet;
        localStorage.setItem(constants_1.localStorageKeys.lastUsedWalletType, constants_1.WalletTypes.CeloExtensionWallet);
        localStorage.setItem(constants_1.localStorageKeys.lastUsedWalletArguments, JSON.stringify([]));
        this.kit = contractkit_1.newKit(network.rpcUrl);
    }
    CeloExtensionWalletConnector.prototype.initialise = function () {
        return __awaiter(this, void 0, void 0, function () {
            var Web3, celo, web3, defaultAccount;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Promise.resolve().then(function () { return __importStar(require('web3')); })];
                    case 1:
                        Web3 = (_a.sent()).default;
                        celo = window.celo;
                        if (!celo) {
                            throw new Error('Celo Extension Wallet not installed');
                        }
                        web3 = new Web3(celo);
                        return [4 /*yield*/, celo.enable()];
                    case 2:
                        _a.sent();
                        // @ts-ignore
                        web3.currentProvider.publicConfigStore.on('update', function (_a) {
                            var networkVersion = _a.networkVersion;
                            return __awaiter(_this, void 0, void 0, function () {
                                return __generator(this, function (_b) {
                                    if (this.onNetworkChangeCallback) {
                                        this.onNetworkChangeCallback(networkVersion);
                                    }
                                    return [2 /*return*/];
                                });
                            });
                        });
                        this.kit = contractkit_1.newKitFromWeb3(web3);
                        return [4 /*yield*/, this.kit.web3.eth.getAccounts()];
                    case 3:
                        defaultAccount = (_a.sent())[0];
                        this.kit.defaultAccount = defaultAccount;
                        return [2 /*return*/, this];
                }
            });
        });
    };
    CeloExtensionWalletConnector.prototype.onNetworkChange = function (callback) {
        this.onNetworkChangeCallback = callback;
    };
    CeloExtensionWalletConnector.prototype.close = function () {
        return;
    };
    return CeloExtensionWalletConnector;
}());
exports.CeloExtensionWalletConnector = CeloExtensionWalletConnector;
var DappKitConnector = /** @class */ (function () {
    function DappKitConnector(network, dappName) {
        this.network = network;
        this.dappName = dappName;
        this.initialised = true;
        this.type = constants_1.WalletTypes.DappKit;
        localStorage.setItem(constants_1.localStorageKeys.lastUsedWalletType, constants_1.WalletTypes.DappKit);
        localStorage.setItem(constants_1.localStorageKeys.lastUsedWalletArguments, JSON.stringify([dappName]));
        var wallet = new dappkit_wallet_1.DappKitWallet(dappName);
        this.kit = contractkit_1.newKit(network.rpcUrl, wallet);
        wallet.setKit(this.kit);
    }
    DappKitConnector.prototype.initialise = function () {
        return __awaiter(this, void 0, void 0, function () {
            var wallet;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        wallet = new dappkit_wallet_1.DappKitWallet(this.dappName);
                        return [4 /*yield*/, wallet.init()];
                    case 1:
                        _a.sent();
                        this.kit = contractkit_1.newKit(this.network.rpcUrl, wallet);
                        this.kit.defaultAccount = wallet.getAccounts()[0];
                        wallet.setKit(this.kit);
                        return [2 /*return*/, this];
                }
            });
        });
    };
    DappKitConnector.prototype.close = function () {
        return;
    };
    return DappKitConnector;
}());
exports.DappKitConnector = DappKitConnector;
var WalletConnectConnector = /** @class */ (function () {
    function WalletConnectConnector(network, options) {
        this.network = network;
        this.initialised = false;
        this.type = constants_1.WalletTypes.WalletConnect;
        localStorage.setItem(constants_1.localStorageKeys.lastUsedWalletType, constants_1.WalletTypes.WalletConnect);
        localStorage.setItem(constants_1.localStorageKeys.lastUsedWalletArguments, JSON.stringify(options));
        var WalletConnectWallet = require('contractkit-walletconnect').WalletConnectWallet;
        var wallet = new WalletConnectWallet(options);
        this.kit = contractkit_1.newKit(network.rpcUrl, wallet);
    }
    WalletConnectConnector.prototype.onUri = function (callback) {
        this.onUriCallback = callback;
    };
    WalletConnectConnector.prototype.initialise = function () {
        return __awaiter(this, void 0, void 0, function () {
            var WalletConnectWallet, wallet, uri, defaultAccount;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        WalletConnectWallet = require('contractkit-walletconnect').WalletConnectWallet;
                        wallet = this.kit.getWallet();
                        return [4 /*yield*/, wallet.getUri()];
                    case 1:
                        uri = _a.sent();
                        if (uri && this.onUriCallback) {
                            this.onUriCallback(uri);
                        }
                        return [4 /*yield*/, wallet.init()];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, wallet.getAccounts()];
                    case 3:
                        defaultAccount = (_a.sent())[0];
                        this.kit.defaultAccount = defaultAccount;
                        return [2 /*return*/, this];
                }
            });
        });
    };
    WalletConnectConnector.prototype.close = function () {
        return __awaiter(this, void 0, void 0, function () {
            var WalletConnectWallet, wallet;
            return __generator(this, function (_a) {
                WalletConnectWallet = require('contractkit-walletconnect').WalletConnectWallet;
                wallet = this.kit.getWallet();
                return [2 /*return*/, wallet.close()];
            });
        });
    };
    return WalletConnectConnector;
}());
exports.WalletConnectConnector = WalletConnectConnector;
