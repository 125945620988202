"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PrivateKey = void 0;
var react_1 = __importStar(require("react"));
var connectors_1 = require("../connectors");
var use_contractkit_1 = require("../use-contractkit");
function PrivateKey(_a) {
    var onSubmit = _a.onSubmit;
    var _b = react_1.useState(''), value = _b[0], setValue = _b[1];
    var network = use_contractkit_1.useContractKit().network;
    var handleSubmit = function () {
        if (!value) {
            return;
        }
        var connector = new connectors_1.PrivateKeyConnector(network, value);
        onSubmit(connector);
    };
    return (react_1.default.createElement("div", { className: "tw-p-2" },
        react_1.default.createElement("div", { className: "tw-flex tw-flex-col" },
            react_1.default.createElement("div", { className: "tw-text-xl tw-text-gray-800 dark:tw-text-gray-200" }, "Enter your plaintext private key"),
            react_1.default.createElement("p", { className: "tw-text-sm tw-mt-2 tw-text-gray-600 dark:tw-text-gray-400" }, "This will be saved locally, so be sure to logout before leaving this computer unattended."),
            react_1.default.createElement("div", { className: "tw-flex tw-flex-col" },
                react_1.default.createElement("textarea", { className: "tw-border tw-border-gray-300 dark:tw-border-gray-700 dark:tw-bg-gray-700 dark:tw-text-gray-300 tw-rounded-md tw-mt-3 tw-px-3 tw-py-2", value: value, onChange: function (e) { return setValue(e.target.value); } }),
                react_1.default.createElement("button", { className: "tw-mt-3 tw-px-4 tw-py-2 tw-border tw-border-transparent tw-rounded-md tw-shadow-sm tw-text-base tw-font-medium tw-text-white tw-bg-gradient-to-r tw-from-purple-600 tw-to-indigo-600 hover:tw-from-purple-700 hover:tw-to-indigo-700", onClick: handleSubmit }, "Submit")))));
}
exports.PrivateKey = PrivateKey;
