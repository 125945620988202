"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DappKitWallet = exports.DappKitSigner = void 0;
var wallet_remote_1 = require("@celo/wallet-remote");
var dappkit_1 = require("./dappkit");
var DappKitSigner = /** @class */ (function () {
    function DappKitSigner(account) {
        var _this = this;
        this.account = account;
        this.getNativeKey = function () { return _this.account; };
    }
    DappKitSigner.prototype.signTransaction = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                throw new Error('signTransaction unimplemented; use signRawTransaction');
            });
        });
    };
    DappKitSigner.prototype.signTypedData = function (typedData) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                throw new Error('signTypedData() not supported by DappKit wallet');
            });
        });
    };
    DappKitSigner.prototype.signPersonalMessage = function (data) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                throw new Error('signPersonalMessage() not supported by DappKit wallet');
            });
        });
    };
    DappKitSigner.prototype.decrypt = function (ciphertext) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                throw new Error('decrypt() not supported by DappKit wallet');
            });
        });
    };
    DappKitSigner.prototype.computeSharedSecret = function (_publicKey) {
        throw new Error('computeSharedSecret() not supported by DappKit wallet');
        return Promise.resolve(Buffer.from([]));
    };
    return DappKitSigner;
}());
exports.DappKitSigner = DappKitSigner;
var randomString = function () { return (Math.random() * 100).toString().slice(0, 6); };
var DappKitWallet = /** @class */ (function (_super) {
    __extends(DappKitWallet, _super);
    function DappKitWallet(dappName) {
        var _this = _super.call(this) || this;
        _this.dappName = dappName;
        /**
         * Override hasAccount for the DappKit wallet as we
         * want to always send users to Valora
         */
        _this.hasAccount = function () { return true; };
        return _this;
    }
    DappKitWallet.prototype.loadAccountSigners = function () {
        return __awaiter(this, void 0, void 0, function () {
            var addressToSigner, requestId, dappkitResponse;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        addressToSigner = new Map();
                        requestId = "login-" + randomString();
                        dappkit_1.requestAccountAddress({
                            requestId: requestId,
                            dappName: this.dappName,
                            callback: window.location.href,
                        });
                        return [4 /*yield*/, dappkit_1.waitForAccountAuth(requestId)];
                    case 1:
                        dappkitResponse = _a.sent();
                        addressToSigner.set(dappkitResponse.address, new DappKitSigner(dappkitResponse.address));
                        return [2 /*return*/, addressToSigner];
                }
            });
        });
    };
    DappKitWallet.prototype.setKit = function (kit) {
        this.kit = kit;
    };
    /**
     * Gets the signer based on the 'from' field in the tx body
     * @param txParams Transaction to sign
     * @dev overrides WalletBase.signTransaction
     */
    // @ts-ignore
    DappKitWallet.prototype.signTransaction = function (txParams) {
        return __awaiter(this, void 0, void 0, function () {
            var requestId, dappkitResponse, raw;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.kit) {
                            throw new Error('Must call setKit before using dappKit wallet');
                        }
                        requestId = "signTransaction-" + randomString();
                        // @ts-ignore
                        dappkit_1.requestTxSig(this.kit, [txParams], {
                            requestId: requestId,
                            dappName: this.dappName,
                            callback: window.location.href,
                        });
                        return [4 /*yield*/, dappkit_1.waitForSignedTxs(requestId)];
                    case 1:
                        dappkitResponse = _a.sent();
                        raw = dappkitResponse.rawTxs[0];
                        return [2 /*return*/, { raw: raw }];
                }
            });
        });
    };
    return DappKitWallet;
}(wallet_remote_1.RemoteWallet));
exports.DappKitWallet = DappKitWallet;
